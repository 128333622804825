import * as React from 'react'
import {  Link } from 'gatsby'
import * as navHeaderStyle from '../styles/navHeader.module.scss'
import { useState } from 'react';



const NavHeaderTitle = ({ children }) => {

  const [toggleNav, setToggleNav]=useState(false)

  const [open, setOpen] = useState(false);

  const mobileNavButton = open ? "toCloseTitle" : "toOpenTitle";

  const showMenu=()=> setToggleNav(!toggleNav);


    return (
      <header className={navHeaderStyle.home}>
          <div className={navHeaderStyle.headerContainer} id={navHeaderStyle.homeHeader}>
          <nav>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en/news">News</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en/eGovernment">eGovernment</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en/projects">Projects</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en/about-us">About us</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/o-spolocnosti/#contact">Contact us</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="../">SK</Link>
              </nav>
          <div id={navHeaderStyle.mobileNavButtonTitleId} className={mobileNavButton} onClick={() => {setOpen(!open);showMenu()}}></div>
              
              
              </div>
              {toggleNav && <div className={navHeaderStyle.mobileNavContainer} id={navHeaderStyle.ttNav}>
                        <nav className={navHeaderStyle.mobileNav} id={navHeaderStyle.ttNavContent}>
                        <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en/news">News</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en/eGovernment">eGovernment</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en/projects">Projects</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en/about-us">About us</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/o-spolocnosti/#contact">Contact us</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="../">SK</Link>
                        </nav>
                </div>}
      </header>
    )
  }
  
  export default NavHeaderTitle